<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<AccountNav></AccountNav>
			</div>
			<div class="admin-col whole_000 whole_fff flex_bd">
				<div class="acc_box api_box">
					<div class="container">
						<div class="acc-hd flex flex-ac flex-zBetween">
							<div class="acc-tit">{{$t('wittext2')}}</div>
						</div>
						<div class="accw-sel">
							<div class="acc-sel-flex">
								<div class="acc-sel-t">{{$t('czTxt2')}}：</div>
								<el-select v-model="symbol" :placeholder="$t('placeholder3')" clearable>
									<el-option v-for="(item,index) in options" :key="index" :label="item" :value="item">
									</el-option>
								</el-select>
							</div>
							<div class="acc-sel-flex">
								<div class="acc-sel-t">{{$t('czTxt6')}}：</div>
								<el-input v-model="amount" :placeholder="$t('witplaceholder')"></el-input>
							</div>
							<div class="acc-in-r acc-btn accw_btn">
								<el-button type="primary" class="btn" @click="getCode">{{$t('witbtn2')}}</el-button>
							</div>
						</div>
						<el-dialog :visible.sync="dialogVisible" width="360px" custom-class="custom-dialog">
							<div slot="title" class="dialog-title"><b>{{$t('witdia')}}</b></div>
							<div class="dialog-content">
								<div class="transfer-cell">
									<el-input v-model="pass" type="password" :placeholder="$t('chtip8')">
									</el-input>
								</div>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button type="primary" @click="applyCode">{{$t('submit')}}</el-button>
							</div>
						</el-dialog>
						<el-dialog :visible.sync="dialogVisible2" width="360px" custom-class="custom-dialog">
							<div slot="title" class="dialog-title"><b>{{$t('witdia2')}}</b></div>
							<div class="dialog-content">
								<div class="transfer-cell">
									<el-input v-model="withdrawCode" readonly>
									</el-input>
								</div>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-row :gutter="14">
									<el-col :span="12">
										<el-button @click="dialogVisible2 = false">{{$t('cancel')}}</el-button>
									</el-col>
									<el-col :span="12">
										<el-button type="primary"  @click="dialogVisible2 = false">{{$t('confirm2')}}</el-button>
									</el-col>
								</el-row>
								
							</div>
						</el-dialog>
						<div class="acc-table exte-table accw-table">
							<div class="api_table_tt">{{$t('wittext3')}}</div>
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="createTime" :label="$t('czth1')">
								</el-table-column>
								<el-table-column prop="coin" :label="$t('czTxt2')">
									<template slot-scope="scope">
										<span>{{ scope.row.coin.unit}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="withdrawAmount" :label="$t('czTxt6')">
								</el-table-column>
								<el-table-column prop="withdrawCode" :label="$t('wittext2')">
								</el-table-column>
								<el-table-column prop="status" :label="$t('wtth8')">
									<template slot-scope="scope">
										<span v-if="scope.row.status == 0">{{$t('acbtn7')}}</span>
										<span v-if="scope.row.status == 1">{{$t('witstate')}}</span>
										<span v-if="scope.row.status == 2">{{$t('witstate2')}}</span>
										<span v-if="scope.row.status == 3">{{$t('witstate3')}}</span>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="exte-box">
							<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange"
								:current-page.sync="currentPage" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		withdrawCoin,
		withdrawRecord,
		withdrawcodeApply
	} from '@/api/api/user'
	import AccountNav from '@/components/AccountNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			AccountNav,
			Foot
		},
		data() {
			return {
				options: [],
				symbol: '',
				amount: '',
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10,
				dialogVisible: false,
				dialogVisible2: false,
				pass: '',
				withdrawCode: 'c10befbd3176ebf65653c85fd83cb7ca'
			};
		},
		mounted() {
			this.getList()
			this.getInfo()
		},
		methods: {
			getList() {
				withdrawRecord({
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					console.log(res)
					if (res.code == 0) {
						this.tableData = res.data.content
						this.total = res.data.totalElements
						this.pageSize = res.data.size
					}
				})
			},
			getInfo() {
				withdrawCoin().then(res => {
					console.log(res)
					if (res.code == 0) {
						this.options = res.data
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			getCode() {
				if (this.symbol == '') {
					this.$message.error(this.$t('witerr'))
				} else if (this.amount == '' && this.amount == 0) {
					this.$message.error(this.$t('witplaceholder'))
				} else {
					this.dialogVisible = true
				}
			},
			applyCode() {
				if (this.pass == '') {
					this.$message.error(this.$t('chtip8'))
				} else {
					withdrawcodeApply({
						amount: this.amount,
						jyPassword: this.pass,
						unit: this.symbol
					}).then(res => {
						if (res.code == 0) {
							this.dialogVisible = false
							this.dialogVisible2 = true
							this.withdrawCode = res.data.withdrawCode
						} else {
							this.$message.error(res.message)
						}
					})
				}
			}
		}
	}
</script>
